<template>
    <div id="login" class="min-h-screen flex items-center justify-center bg-indigo-500 py-12 px-4 sm:px-6 lg:px-8">
        <div class="w-1/4 space-y-6 border-2 border-gray-50 rounded-md p-10 shadow-lg bg-gray-50 flex flex-col items-center justify-center">
            <img alt="daiteo_logo" :src="require('@/assets/img/Logo-Daiteo-2.svg')" class="w-40 h-20">
            <input v-model="username" id="username" name="username" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nom d'utilisateur ...">
            <input v-model="password" @keyup.enter="login()" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Mots de passe ...">
            <p class="text-red-500">{{ this.error }}</p>
            <button @click="login()" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Connexion
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

if (process.env.NODE_ENV === 'development')
    axios.defaults.baseURL = 'http://localhost:8000'
else
    axios.defaults.baseURL = 'https://analytics.daiteo.io'

export default {
    name: 'Login',
    props: [''],
    data: () => {
        return ({
            username: '',
            password: '',
            error: '',
        })
    },
    methods: {
        async login () {
            if (this.username && this.password) {
                let payload = {
                    'username': this.username,
                    'password': this.password,
                }
                await axios.post('/api/token/', payload)
                .then((response) => {
                    this.error = ''
                    this.username = ''
                    this.password = ''
                    localStorage.setItem('accessToken', response.data.access)
                    localStorage.setItem('refreshToken', response.data.refresh)
                    this.$router.push({name: 'Global'})
                })
                .catch(() => {
                    this.error = 'Error on username or password'
                    this.password = ''
                })
            }
        }
    },
    computed: {
    },
    mounted: function () {
    }
}
</script>

<style scoped>
</style>